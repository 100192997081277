.activity-sub-header {
  position: fixed;
  margin-top: -17px;
  width: 100%;
  background: white;
  z-index: 9;
}

.activity-card-wrapper {
  padding: 1rem 0.75rem;
  margin: 1rem;
}

.activity-card-wrapper .btn {
  font-size: var(--font-size-bigger);
  color: var(--color-gray-600);
}

.activity-card-wrapper .fa {
  font-size: var(--font-size-bigger);
  color: var(--color-gray-600);
}

.activity-card-wrapper .fa.-active {
  color: var(--color-violet-400);
}

.activity-card-wrapper .created-at {
  font-size: var(--font-size);
}

.activity-card-wrapper .card-badge {
  font-size: small;
  background-color: var(--color-gray-100);
  padding: 0.4rem 0.7rem;
  border-radius: 8px;
  float: left;
}

.activity-card-wrapper .card-follow {
  float: right;
}

.activity-card-wrapper .card-body {
  padding-bottom: 0;
  padding-left: 0.3rem;
  padding-top: 0.5rem;
  padding-right: 0.3rem;
}

.activity-card-wrapper .card-body .description {
  font-size: var(--font-size);
  padding-top: var(--spacing-8);
}

@media (min-width: 576px) {
  .activity-wrapper {
    padding: 0rem 3rem 5rem;
  }

  .activity-card-wrapper {
    padding: 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
}
