/* header css */
a.search-active {
  background-color: var(--color-white);
  border-radius: 50% 50% 0 0;
  padding: 0.85rem !important;
  position: relative;
  top: 3px;
  /* -webkit-mask-image: radial-gradient(circle 10px at 0 0, transparent 10px, transparent 20px, black 21px); */
}

a.search-active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 2px;
  left: -25px;
  height: 35px;
  width: 25px;
  border-bottom-right-radius: 20px;
  box-shadow: 5px 9px 0 0 var(--color-white);
  z-index: -99;
}

a.search-active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 2px;
  right: -25px;
  height: 35px;
  width: 25px;
  border-bottom-left-radius: 20px;
  box-shadow: -5px 9px 0 0 var(--color-white);
  z-index: -99;
}

.search-input {
  min-height: 4vh;
  border-radius: 20px !important;
  background-color: var(--color-violet-100) !important;
  border: none !important;
  font-size: var(--font-size) !important;
}

.profile-user a.username {
  color: var(--color-black) !important;
  font-size: var(--font-size);
  float: left;
}

.profile-user a.username .actual-name {
  color: var(--color-gray-900);
  font-size: var(--font-size-small);
  position: relative;
  top: -5px;
}

.icon-search-chat {
  width: 20px !important;
  height: 20px !important;
}

/* Priority users */
.priority-user-list {
  border: 2px solid var(--color-gray-200);
  border-radius: var(--spacing-16);
  padding: var(--spacing-12) var(--spacing-16) 0;
}

.priority-user-list .priority-user-list-header {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  text-align: center;
}

.priority-user-list .priority-user {
  margin: var(--spacing-12) 0;
}

.priority-user-list .priority-user .search-user {
  padding: 0 !important;
}

@media (max-width: 576px) {
  .search-subheader {
    /* overflow-x: scroll !important; */
  }

  ::-webkit-scrollbar {
    display: none !important;
  }

  .search-subheader .tab-item {
    min-width: 50vw;
  }
}
