.challenges-wrapper {
  text-align: center;
  padding: 5rem;
}

.challenges-wrapper .launch-icon {
  width: 42px;
  animation: wiggle 4s infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes wiggle {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.challenges-wrapper .launch-text {
  font-size: 13px;
  font-weight: 800;
}
