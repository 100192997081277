.bottom-sm-padding {
  /* padding-top: 25px;
  padding-bottom: 25px; */
}

.navbar-bottom {
  position: fixed;
  bottom: -1px;
  width: 100%;
  z-index: 99;
}

.footer-wrapper {
  position: relative;
  width: 100%;
  background-color: var(--color-gray-100);
}

/* Ref: https://itnext.io/how-to-make-a-fancy-inverted-border-radius-in-css-5db048a53f95 */
.footer-wrapper::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 43px;
  left: 0px;
  height: 50px;
  width: 25px;
  border-bottom-left-radius: 35px;
  box-shadow: 0 18px 0 0 var(--color-gray-100);
}

.footer-wrapper::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 43px;
  right: 0px;
  height: 50px;
  width: 25px;
  border-bottom-right-radius: 35px;
  box-shadow: 0px 18px 0 0 var(--color-gray-100);
}

.navbar-bottom .nav-item {
  border-bottom: 3px solid transparent;
}

.navbar-bottom .nav-item-active {
  border-bottom: 3px solid #fff;
}

.navbar-bottom .nav-link a {
  color: #000;
  text-decoration: none;
}

.navbar-bottom .nav-link a:hover {
  text-decoration: none;
}

.navbar-bottom .create-post {
  position: absolute;
  top: -17px;
  left: 43.75%;
  background-color: var(--color-violet-400);
  border-radius: 50%;
  width: 53px;
  height: 53px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.navbar-bottom .create-post .plus-c-icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: 4px;
}

.navbar-bottom .home-icon {
  width: 22px;
  height: 22px;
}

.navbar-bottom .profile-icon {
  width: 22px;
  height: 22px;
}
