.icon-heart {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.icon-heart.active {
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
}

.scaleInImgFollow {
  animation: scaleIn 0.75s 2;
}

.scaleInImgUnfollow {
  animation: scaleIn 0.75s 1;
}

@-webkit-keyframes scaleIn {
  0% {
    transform: scale(1);
    filter: none;
  }

  50% {
    transform: scale(1.7);
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
  }

  100% {
    transform: scale(1);
    filter: none;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(1);
    filter: none;
  }

  50% {
    transform: scale(1.7);
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
  }

  100% {
    transform: scale(1);
    filter: none;
  }
}

.follow-blog {
  text-align: center;
  cursor: pointer;
  padding-top: var(--spacing-3);
}

.follow-blog .follower-length {
  color: #f42222;
  position: relative;
  top: -7px;
  left: var(--spacing-12);
  font-size: var(--font-size-bigger);
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.25));
}

.blogs-wrapper {
  margin-top: var(--spacing-32);
  padding: var(--spacing-128);
}

#navbar-brand-logo-blog {
  width: 9rem !important;
}

.blog-footer a {
  text-decoration: none;
  color: #fff;
}

.blog-footer a:hover {
  color: #808080;
}

.blog-header .navbar-nav a {
  margin-top: 0.5rem;
}

.blogs-wrapper .blog-img {
  border-radius: var(--spacing-32);
  width: 100% !important;
  object-fit: cover;
}

.blogs-wrapper .btn-violet-outline {
  width: fit-content;
  outline: none;
  color: black !important;
  border: none;
}

.blogs-wrapper .blog-category {
  border-radius: var(--spacing-16);
  font-family: "Quicksand", sans-serif !important;
  font-size: var(--font-size-bigger);
  font-weight: bolder;
}

.blogs-wrapper .blog-header {
  cursor: pointer;
  font-weight: bolder;
  font-family: "Quicksand", sans-serif !important;

  /* font-size: 2rem !important; */
}

.blogs-wrapper .card {
  border: 0 solid;
  border-radius: var(--spacing-16);
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  transition: transform 100ms ease-in;
}

.blogs-wrapper .card:hover {
  transform: scale(1.05);
}

.blogs-wrapper .card .blog-img {
  max-height: 17rem !important;
  object-fit: cover;
  border-radius: var(--spacing-16) var(--spacing-16) 0 0;
}

.blog-footer-section-wrapper {
  width: 100%;
  margin-top: var(--spacing-208);
  position: static;
  flex-shrink: 0;
}

.blog-footer-background-with-content {
  position: relative;
  z-index: 1;
}

.blog-footer-background-desktop {
  width: 100vw;
  height: 100%;
}

.blog-footer-links {
  line-height: var(--spacing-32);
}

.blog-footer-links:hover {
  color: var(--color-white) !important;
}

.blog-footer .company-name {
  font-size: var(--font-size-small);
  margin-top: var(--spacing-12);
}

.blog-footer .follow-us {
  font-size: var(--font-size-bigger);
}

.blog-footer .social-icon {
  padding-left: var(--spacing-24);
}

.blog-footer .social-icon img {
  width: var(--spacing-24);
  height: var(--spacing-24);
}

.blog-footer {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 1.1;
  text-align: left;
  top: 25%;
  left: 15%;
  width: 35vw;
}

.blog-footer .right-wrapper {
  text-align: right;
}

#blog-cta {
  color: #fff !important;
  margin-left: 35vw;
  margin-right: 35vw;
}

.navbar-brand-logo {
  padding-top: var(--spacing-24) !important;
}

.blogs-wrapper .col-3 .d-flex {
  position: sticky;
  top: 8%;
}

.blogs-wrapper .row .col-sm-4 .card {
  align-self: center !important;
}

.badge-category {
  position: absolute;
  left: 4%;
  top: 6%;
  width: 3rem;
}

/* Progress bar */
#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  top: 14%;
  left: 0;
}

#progressBar {
  background: linear-gradient(to left, #7c3bff, #7c3bff);
  transform-origin: top left;
  transform: scale(0, 0);
  opacity: 0;
}

#progressBarContainer,
#progressBar {
  height: 10px;
}

.footer-section-mobile-wrapper {
  width: 100%;
  position: relative;
}

.mobile .blog-footer-background-mobile {
  margin-top: 9rem;
  height: 75vh;
  width: 100vw;
}

.mobile .overlay-footer-links,
.company-info,
.overlay-footer-links-follow {
  position: absolute;
  z-index: 3;
  color: #fff;
  opacity: 1.1;
  text-align: center;
  word-spacing: 0.1rem;
  bottom: 60%;
}

.mobile .blog-footer-links {
  color: #fff;
  text-decoration: none;
}

.mobile .overlay-footer-links {
  bottom: 30%;
}

.company-info {
  top: 70%;
}

@media only screen and (max-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  #progressBarContainer {
    top: 6%;
  }
}

@media only screen and (max-device-width: 768px) and (max-device-height: 1024px) {
  .blog-header {
    margin-top: 0;
    margin-bottom: 0.4rem !important;
  }

  #progressBarContainer {
    top: 6.8%;
  }

  .blog-footer-background-desktop {
    height: 40vh;
  }

  .col-4 .blog-footer {
    margin-left: -2rem;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) {
  #progressBarContainer {
    top: 10.5%;
  }
}

@media only screen and (max-device-width: 320px) {
  #navbar-brand-logo-blog {
    margin-left: -0.1rem !important;
  }

  .mobile .blog-footer-background-mobile {
    margin-top: 10rem;
    height: 90vh;
    width: 100vw;
  }
}

@media only screen and (min-width: 375px) and (max-width: 413px) {
  #navbar-brand-logo-blog {
    margin-left: var(--spacing-40) !important;
  }
}

@media only screen and (min-width: 540px) and (min-height: 720px) {
  #navbar-brand-logo-blog {
    margin-left: var(--spacing-96) !important;
  }

  .blogs-wrapper .row .col-sm-4 .card {
    height: 22rem !important;
  }
}

@media only screen and (max-width: 768px) and (max-height: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .blog-nav-options {
    margin-top: -0.7rem;
  }

  .blog-nav-options .btn-violet-outline {
    margin-right: 8rem !important;
  }

  .blog-nav-options .btn-violet-news {
    margin-left: 12rem !important;
  }
}

@media only screen and (min-device-width: 414px) and (min-device-height: 736px) {
  #navbar-brand-logo-blog {
    margin-left: var(--spacing-40) !important;
  }
}

@media (min-width: 768px) {
  .blogs-wrapper .row .col-sm-4 .card {
    height: 22rem !important;
  }
}
