.feeling-wrapper {
  padding-top: var(--spacing-24) !important;
}

.feeling-wrapper .filter-wrapper {
  padding: var(--spacing-12) var(--spacing-12) 0 var(--spacing-16);
  overflow-x: auto;
}

.feeling-wrapper .filter-wrapper .filter-icon {
  float: left;
}

.feeling-wrapper .filter-wrapper .filter-names {
  padding: 0 var(--spacing-12);
  overflow-x: scroll;
  white-space: nowrap;
  /* transition: visibility 0s linear 0.33s, opacity 0.33s linear; */
}

.show-filter-names {
  display: block;
  animation: showFilter 0.35s 1;
}

.hide-filter-names {
  display: none;
  animation: hideFilter 0.35s 1;
}

@keyframes showFilter {
  from {
    transform: translateX(+200vw);
  }
  to {
    transform: translateX(0vw);
  }
}

@keyframes hideFilter {
  from {
    transform: translateX(0vw);
  }
  to {
    transform: translateX(-100vw);
  }
}

.feeling-wrapper .filter-wrapper .unselected {
  border: 1px solid #f4effd;
  border-radius: 6px;
}

.feeling-wrapper .filter-wrapper .selected-filter {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.feeling-wrapper .filter-wrapper .selected-filter-special {
  font-size: var(--font-size-small);
  color: var(--color-white);
  background-color: var(--color-special);
  padding: 0.25rem 0.7rem;
  border-radius: 1.5rem;
}

.feeling-wrapper .scroll-to-top {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--font-size);
  color: var(--color-white);
  background-color: var(--color-violet-400);
  border-radius: 1.5rem;
  cursor: pointer;
}

.product-card .card {
  padding: var(--spacing-24);
  /* background: var(--color-orange-100); */
  border: 0px solid var(--color-violet-100);
  border-radius: var(--spacing-12);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.product-card .card .icon-document-download {
  width: var(--spacing-20);
  height: var(--spacing-20);
}

@media (max-width: 575.97px) {
  .scroll-to-top {
    top: calc(100vh - 16vh);
  }
}

@media (min-width: 576px) {
  .scroll-to-top {
    bottom: 3vh;
  }
}

@media (min-width: 992px) {
  .feeling-wrapper {
    padding: 0rem 3rem;
  }
}
