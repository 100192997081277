.invite-friends-wrapper {
  margin-top: var(--spacing-128);
}

.invite-friends-wrapper .lg-input {
  background-color: var(--color-violet-100);
  border: 0;
  padding: var(--spacing-8) var(--spacing-12);
  border-radius: var(--spacing-24);
  min-width: 30vw;
  text-align: center;
  font-size: var(--font-size);
}
