.audio-player-wrapper {
  padding: var(--spacing-16) var(--spacing-12) 0;
}

.audio-player-wrapper .audio-btn {
  text-align: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: none;
  margin: 0px auto;
}

.audio-player-wrapper .audio-btn .btn-play {
  width: 4vw;
  position: relative;
  top: 4px;
  left: 1px;
  transition: all 0.25s ease-in-out;
}

.audio-player-wrapper .audio-btn .btn-pause {
  width: 3vw;
  position: relative;
  top: 4px;
  transition: all 0.25s ease-in-out;
}

.audio-player-wrapper .audio-time .current {
  font-size: var(--font-size-small);
}

.audio-player-wrapper .audio-time .duration {
  font-size: var(--font-size-small);
  float: right;
}

.audio-player-wrapper .range {
  width: 100%;
  height: 2px;
  border: 1px solid #000000;
  border-radius: 25px;
  cursor: pointer;
  position: relative;
  top: -7px;
  -webkit-appearance: none;
}

.audio-player-wrapper .range::-webkit-slider-thumb {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: var(--color-white);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  position: relative;
  top: -1px;
  -webkit-appearance: none;
  z-index: 99;
}

.audio-player-wrapper .progress {
  height: 2px;
  position: relative;
  top: -15px;
  background: #2b2b2b !important;
}

.audio-player-wrapper .progress-bar {
  background-color: var(--color-violet-400) !important;
}
