.section-two-wrapper {
  text-align: center;
}

.section-two-wrapper .header {
  color: var(--color-violet-700);
  letter-spacing: -1px !important;
}

.section-two-wrapper .lead {
  color: var(--color-violet-700);
}

.section-two-wrapper .steps-header {
  color: var(--color-violet-700);
  letter-spacing: -1px !important;
}

.section-two-wrapper .steps-body {
  color: var(--color-violet-700);
  line-height: var(--spacing-24);
}

.section-two-wrapper .puzzle-gif {
  /* height: 50%; */
}

.section-two-wrapper .puzzle-gif:nth-child(1) {
  /* border-radius: var(--spacing-64); */
}

.section-two-wrapper .consultation-image-2 {
  transform: scale(1.2);
}

.hr-violet {
  background-color: var(--color-violet-400);
}

.hr-white {
  background-color: white;
}

@media (max-width: 576px) {
  .section-two-wrapper .steps-body {
    font-size: 18px;
    line-height: var(--spacing-32);
  }

  .section-two-wrapper .consultation-image-4 {
    transform: scale(1.2);
  }
}
