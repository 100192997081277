.section-one-wrapper {
  padding-top: var(--spacing-32);
  background-color: var(--color-orange-100);
  color: var(--color-violet-600);
}

.section-one-wrapper ul {
  list-style: none;
}

.section-one-wrapper ul i.fa {
  color: var(--color-orange-600);
}

@media (max-width: 576px) {
  .section-one-wrapper {
    padding-top: var(--spacing-64);
  }

  .home-image-1 {
    padding: var(--spacing-64);
    transform: scale(1.6);
  }
}

@media (min-width: 577px) {
  .home-image-1 {
    padding-top: var(--spacing-96);
    padding-bottom: var(--spacing-80);
    transform: scale(1.4);
  }
}
