.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-black);
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--color-black);
}

.separator:not(:empty)::before {
  margin-right: var(--spacing-16);
}

.separator:not(:empty)::after {
  margin-left: var(--spacing-16);
}

.login-page {
  font-weight: var(--font-weight-medium);
  padding-bottom: var(--spacing-160);
}

.login-page .caption-text {
  color: var(--color-gray-700);
  font-size: var(--font-size-small);
}

.login-page .img-nmc-logo {
  width: 350px;
  object-fit: contain;
}

.login-page .img-medoc-logo {
  width: 100px;
}

@media (min-width: 576px) {
  .login-page {
    margin-top: var(--spacing-128);
  }
}
