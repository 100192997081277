.change-pc-wrapper {
  padding: var(--spacing-48) var(--spacing-32);
}

.change-pc-wrapper .form-group {
  position: relative;
  text-align: center;
  margin: var(--spacing-48) auto;
}

.change-pc-wrapper .form-label {
  font-size: var(--font-size-big);
  font-weight: 500;
}

.change-pc-wrapper .form-control,
.change-pc-wrapper .form-control:active,
.change-pc-wrapper .form-control:focus,
.change-pc-wrapper .form-control:hover {
  /* padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 200px;
  min-width: 200px; */

  border: none;
  background: repeating-linear-gradient(
      90deg,
      dimgrey 0,
      dimgrey 15vw,
      transparent 0,
      transparent 20vw
    )
    0 100%/100% 2px no-repeat;
  letter-spacing: 0.5ch;

  color: var(--color-black);
  padding: 0;
  margin: 0;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: var(--font-size);
  box-shadow: none !important;
}

.change-pc-wrapper .otp-message {
  padding: var(--spacing-32) 0;
}

.pw-visibility-icon {
  width: 20px;
  width: 20px;
  position: absolute;
  top: 33px;
  right: 10px;
}
