/* trade range */
.range {
  height: var(--spacing-8);
  border: 1px solid var(--color-violet-400);
  background: var(--color-violet-400);
  border-radius: 25px;
  position: relative;
  top: -8px;
  -webkit-appearance: none;
}

.range::-webkit-slider-thumb {
  width: var(--spacing-16);
  height: var(--spacing-16);
  border-radius: 50%;
  background: var(--color-violet-400);
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35); */
  cursor: pointer;
  position: relative;
  top: -1px;
  -webkit-appearance: none;
  z-index: 99;
}
