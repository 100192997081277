/* Subheader */
.feeling-sub-header {
  margin-top: -12px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.feeling-sub-header div {
  padding: 0.4rem;
}

.feeling-sub-header a {
  color: #1a1a2e;
  font-size: 12px;
  font-weight: 900;
  padding: 0.5rem 1rem;
}

.feeling-sub-header div:nth-child(1) {
  border-bottom: 2px solid;
}

/* Create post wrapper - steps */
.create-post-wrapper {
  padding: 1.5rem 0;
}

.feeling-detail-wrapper,
.feeling-category-wrapper,
.feeling-submit-wrapper {
  font-size: var(--font-size);
}

.feeling-detail-wrapper {
  position: relative;
  margin-bottom: var(--spacing-16);
}

.feeling-detail-wrapper .description textarea {
  font-size: var(--font-size);
  border: none;
}

.feeling-detail-wrapper .trash-icon {
  width: 19px;
}

.feeling-submit-wrapper {
  display: flex;
}

.feeling-submit-wrapper .display-right {
  margin-left: auto;
}

.feeling-submit-wrapper .checkbox-icon {
  width: 19px;
  transform: scale(1.1);
}

.attachment-icon {
  width: var(--font-size-bigger);
}

/* custom select */
.custom-select {
  border: none !important;
  box-shadow: none !important;
}
