.wallet-recharge-wrapper {
  padding: var(--spacing-48) var(--spacing-32);
}

.wallet-recharge-wrapper .form-label {
  font-size: var(--font-size-small);
  margin: 0;
}

/* Stripe card */
.CardNumberField-input-wrapper {
  width: 300px !important;
}

.CardNumberField-input-wrapper .InputContainer .InputElement {
  width: 200px !important;
}

.CardBrandIcon-container.is-hidden {
  width: 50px !important;
}
