#createChatModal .chat-user .username {
  position: relative;
  top: -2px;
}

.btn-send {
  width: 17px;
  height: 17px;
}

#createChatModal .btn-send {
  position: relative;
  top: -10px;
}

@media (max-width: 767.98px) {
  #createChatModal .user-list {
    max-height: 50vh;
    overflow: scroll;
  }

  #createChatModal .modal-body {
    min-height: 35vh !important;
    max-height: 70vh !important;
  }

  #createChatModal .chat-user {
    padding: var(--spacing-4) 0;
  }

  #createChatModal .media-body {
    align-items: baseline;
  }
}

@media (min-width: 768px) {
  .accessibility-icon {
    display: none;
  }

  #createChatModal .modal-body {
    min-height: 35vh !important;
    max-height: 45vh !important;
    overflow-y: scroll;
    padding: 0 var(--spacing-32) 0;
  }

  #createChatModal .media-body {
    min-width: 15vw !important;
  }

  #createChatModal .chat-user {
    padding: var(--spacing-12) 0;
  }
}
