/* header */
.navbar-violet {
  background-color: var(--color-violet-400);
  min-height: var(--spacing-64);
}

.navbar-violet .navbar-nav {
  flex-direction: row !important;
}

.navbar-violet .navbar-brand {
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
}

.navbar-violet .nav-link img.icon-search,
.navbar-violet .nav-link img.icon-chat,
.navbar-violet .nav-link img.icon-alert {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.navbar-violet .nav-link img.icon-document-download {
  width: 23px;
  height: 23px;
}

.navbar-violet .nav-link img.icon-alert {
  width: 28px;
  height: 28px;
}

.nav-link sup {
  position: relative;
}

.dot-chat-icon {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: -12px;
}

.dot-alert-icon {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: -15px;
}

@media (min-width: 576px) {
  .navbar-violet .nav-link img.icon-document-download {
    width: 22px;
    height: 22px;
  }

  .navbar-violet .nav-link img.icon-alert {
    width: 27px;
    height: 22px;
  }
}
