.profile-graphs-container {
  width: 100vw;
  text-align: center;
  margin: 0px auto !important;
}

@media (max-width: 767.97px) {
  .profile-graphs-container {
    /* display: none; */
  }
}

@media (min-width: 768.97px) {
  .profile-graphs-container .graphs {
    position: relative;
    left: 10vw;
    width: 30vw;
    top: var(--spacing-48);
  }
}
