.edit-profile-wrapper {
  padding: var(--spacing-48) var(--spacing-32);
}

.edit-profile-wrapper .upload-image {
  text-align: center;
  padding: var(--spacing-24) 0;
  position: relative;
}

.edit-profile-wrapper .upload-image .camera-icon-wrapper {
  position: absolute;
  bottom: 20%;
  left: 55%;
  text-align: center;
  background: var(--color-white);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.edit-profile-wrapper .upload-image .camera-icon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.edit-profile-wrapper .form-label {
  font-size: var(--font-size-small);
  margin: 0;
}

.edit-profile-wrapper .form-control,
.edit-profile-wrapper .form-control:active,
.edit-profile-wrapper .form-control:focus,
.edit-profile-wrapper .form-control:hover {
  color: var(--color-black);
  padding: 0;
  margin: 0;
  border-width: 0;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 0;
  background-color: transparent;
}

.edit-profile-wrapper textarea.form-control {
  resize: none;
}

.edit-profile-wrapper input:read-only {
  background-color: transparent;
}

.edit-profile-wrapper input[type="date"] {
  outline: none !important;
  box-shadow: none !important;
}

@media (max-width: 576px) {
}
