.change-pw-wrapper {
  padding: var(--spacing-48) var(--spacing-32);
}

.change-pw-wrapper .form-group {
  position: relative;
}

.change-pw-wrapper .form-label {
  font-size: var(--font-size-small);
  margin: 0;
}

.change-pw-wrapper .form-control,
.change-pw-wrapper .form-control:active,
.change-pw-wrapper .form-control:focus,
.change-pw-wrapper .form-control:hover {
  color: var(--color-black);
  padding: 0;
  margin: 0;
  border-width: 0;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 0;
  background-color: transparent;
  font-size: var(--font-size);
  box-shadow: none !important;
}

.pw-visibility-icon {
  width: 20px;
  width: 20px;
  position: absolute;
  top: 33px;
  right: 10px;
}

@media (max-width: 576px) {
}
