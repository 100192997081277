/* header css */
a.bell-active {
  background-color: var(--color-white);
  border-radius: 50% 0 0 0;
  padding: 0.85rem !important;
  position: relative;
  top: 2px;
  left: 10px;
  /* -webkit-mask-image: radial-gradient(circle 10px at 0 0, transparent 10px, transparent 20px, black 21px); */
}

a.bell-active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 2px;
  left: -25px;
  height: 35px;
  width: 25px;
  border-bottom-right-radius: 20px;
  box-shadow: 5px 9px 0 0 var(--color-white);
  z-index: -99;
}

.alerts-wrapper {
  padding-top: 40px;
  padding-bottom: 20px;
}

.pending-request .badge.pending-count {
  width: 17px;
  height: 17px;
  padding-top: 4px;
  left: -15px;
}

.alerts-wrapper .mark-alerts {
  cursor: pointer;
  font-size: var(--font-size-small);
}

.alerts-wrapper .alert-dot {
  left: 4px;
}

.alerts-wrapper .card {
  border-width: 0px;
  border-bottom: 1px solid var(--color-gray) !important;
  /* box-shadow: 1px 1px 2px gainsboro; */
}

.alerts-wrapper .created-at {
  color: #2b2b2b;
  font-size: var(--font-size-smaller);
}
