.activity-sub-header {
  margin-top: -12px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.activity-sub-header div {
  padding: 0.4rem;
}

.activity-sub-header a {
  color: #1a1a2e;
  font-size: 12px;
  font-weight: 900;
  padding: 0.5rem 1rem;
}

.activity-sub-header div:nth-child(2) {
  border-bottom: 2px solid;
}

.activity-name-wrapper {
  font-size: var(--font-small) !important;
  background: var(--color-white);
  margin: var(--spacing-32) var(--spacing-16);
  padding: var(--spacing-4) var(--spacing-16);
}

.activity-name-wrapper .header {
  padding: 1rem 0 0;
}

.activity-name-wrapper .options {
  line-height: 2;
}

/* Description */
.activity-detail-wrapper {
  font-size: var(--font-small) !important;
}

.activity-submit-wrapper {
  display: flex;
  margin-top: var(--spacing-8);
}

.activity-submit-wrapper .display-right {
  margin-left: auto;
}

.checkbox-icon {
  width: 21px;
}
