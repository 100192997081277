.navbar-violet-md {
  background: var(--color-violet-400);
  border-radius: 0 0 1rem 1rem;
  min-height: var(--spacing-64);
  padding-left: var(--spacing-24);
}

.navbar-violet-md .navbar-brand {
  color: var(--color-white);
  font-size: var(--font-size-big) !important;
  font-weight: 500 !important;
}

.settings-wrapper .section {
  padding-left: var(--spacing-12);
  padding-right: var(--spacing-12);
}

.settings-wrapper .section-header {
  font-size: var(--font-size);
  font-weight: 600;
  padding: var(--spacing-4) 0 var(--spacing-8);
  /* color: #dc6535; */
}

.settings-wrapper .section:first-child .section-header {
  padding-top: var(--spacing-32);
}

.settings-wrapper .section-list {
  list-style: none;
  padding: 0;
}

.settings-wrapper .section-list li {
  padding: 0.7rem 0;
  align-items: center;
}

.settings-wrapper .section-list li .option-icon {
}

.settings-wrapper .section-list li .option-name {
  color: #000000;
  padding-left: 0;
  font-size: 14px;
}

.settings-wrapper .section-list li .option-open {
  height: 14px;
}

.scale-0-9 {
  transform: scale(0.9);
}

.scale-1-1 {
  transform: scale(1.1);
}

.scale-1-2 {
  transform: scale(1.2);
}

.scale-1-3 {
  transform: scale(1.3);
}

.scale-1-4 {
  transform: scale(1.4);
}

.scale-1-4 {
  transform: scale(1.4);
}

.scale-1-5 {
  transform: scale(1.5);
}

.settings-wrapper .row img:first-child {
  width: 22px;
  height: 22px;
  padding: 0px !important;
}

/* Start: Toggle switch colors */
/* Ref: https://stackoverflow.com/questions/64435290/how-can-i-change-the-checked-background-color-of-this-bootstrap-4-toggle-switc */
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--color-violet-400) !important;
  background-color: var(--color-violet-400) !important;
}

.custom-control-input:active ~ .custom-control-label::before {
  border-color: var(--color-violet-400) !important;
  background-color: var(--color-violet-400) !important;
}
/* End: Toggle switch colors */

@media (max-width: 576px) {
  .settings-wrapper {
    padding-top: var(--spacing-16);
    background-color: var(--color-white);
  }
}
