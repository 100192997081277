.section-one-wrapper {
  padding-top: var(--spacing-32);
  background-color: var(--color-orange-100);
  color: var(--color-violet-600);
}

.section-one-wrapper ul {
  list-style: none;
}

.section-one-wrapper ul i.fa {
  color: var(--color-orange-600);
}

.consultation-image-1 {
  height: 100%;
  transform: scale(0.8);
}

@media (max-width: 576px) {
  .section-one-wrapper {
    padding-top: var(--spacing-64);
  }
}
