.emotion-score-wrapper .username {
  font-size: var(--font-size-small);
  color: var(--color-violet-400);
}

.emotion-score-wrapper .emotion-score {
  font-size: var(--font-size-bigger);
  margin: var(--spacing-8) 0;
}

.emotion-score-wrapper .question-mark {
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-4);
  width: var(--spacing-24);
  height: var(--spacing-24);
  border-radius: 50% !important;
  position: relative;
}

.emotion-score-wrapper .question-mark span {
  position: absolute;
  top: 0;
  left: 7px;
}

.emotion-score-wrapper .score-description {
  font-size: var(--font-size-smaller);
}
