.go-back-icon {
  margin-right: 10px;
}

.settings-icon {
  width: 27px;
}

.dot-icon {
  width: 10px;
}

.save-icon {
  width: 16px;
}
