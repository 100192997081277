.section-three-wrapper {
  text-align: center;
}

.section-three-wrapper .header {
  color: var(--color-violet-700);
  letter-spacing: -1px !important;
}

.section-three-wrapper .lead {
  color: var(--color-violet-700);
}

.testimonial-card {
  border: 0px;
  border-radius: var(--spacing-12);
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.07),
    0 6px 18px -1px rgba(0, 0, 0, 0.04) !important;
}
