.signup-page .login-form .left-block {
  padding: var(--spacing-48) var(--spacing-64);
}

.signup-page .login-form .left-block .header {
  padding-left: 0;
  font-family: "Quicksand", sans-serif !important;
}

.signup-page .login-form .left-block .header h1 {
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0.035rem;
  font-family: "Quicksand", sans-serif !important;
}

.signup-page .login-form .left-block .header .subheader {
  font-size: 16px;
}

.signup-page .login-form .left-block .options {
  padding: var(--spacing-24) 0;
  height: 28vh;
}

.signup-page .options {
  text-align: left;
  padding: 0 var(--spacing-16);
  font-size: var(--font-size-small);
  /* color: #8a8a8a; */
}

.signup-page .center-wrapper {
  padding: var(--spacing-8) 0;
}

.signup-page .center-wrapper .option {
  padding: var(--spacing-12) 0;
  align-items: center;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 0.025rem;
}

.signup-page .center-wrapper .option .icon {
  border: 1px solid var(--color-white);
  border-radius: 13px;
  padding: var(--spacing-8);
  text-align: center;
  margin-right: var(--spacing-12);
}

.signup-page .center-wrapper .option .icon img {
  width: 1.6rem;
}

.signup-page .center-wrapper .option .option-text {
  font-family: "Quicksand", sans-serif !important;
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .signup-page .login-form .left-block {
    text-align: center;
    padding: var(--spacing-48) var(--spacing-32);
  }
  .signup-page .center-wrapper .option .icon {
    margin: 0px auto;
    padding: var(--spacing-16);
  }
  .signup-page .center-wrapper .option .icon img {
    width: 2.6rem;
  }
  .signup-page .center-wrapper .option {
    padding-top: var(--spacing-16);
    font-size: 1.3rem;
    letter-spacing: 0.025rem;
  }
}

@media (min-width: 768px) {
  .signup-page .login-form .left-block .header {
    /* width: 20vw; */
  }

  .signup-page .login-form .left-block .subheader {
    /* width: 20vw; */
  }

  .signup-page .login-form .left-block .options {
    height: 33vh;
  }
}
