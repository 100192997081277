/* Login page */
.login-page {
  /* background-image: url("assets/images/login.png"); */
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: scroll;
}

.login-form .row {
  padding: var(--spacing-160) var(--spacing-16) 0;
}

/* Login page : Left block */
.login-form .left-block {
  background: rgba(105, 48, 219, 0.7);
  box-sizing: border-box;
  color: var(--color-white);
}

.login-form .left-block .header {
  padding: var(--spacing-48) var(--spacing-12) var(--spacing-16);
}

.login-form .left-block .body {
  padding: 0 var(--spacing-12) var(--spacing-24);
}

.login-form .left-block .body img:first-child {
  width: 11vw;
}

.login-form .left-block .body .learn-more {
  cursor: pointer;
}

/* Login page : Right block */
.login-form .right-block {
  background: var(--color-white);
  box-shadow: -6px 4px 39px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.login-form img.show-password {
  cursor: pointer;
  width: 24px !important;
  height: 24px !important;
  top: 0px !important;
}

.login-form .right-block .header {
  padding: var(--spacing-48) var(--spacing-12) var(--spacing-16);
}

.login-form .right-block .body {
  padding: 0 var(--spacing-32) var(--spacing-64);
}

.login-form .right-block .body input {
  min-height: 50px;
  border-radius: var(--spacing-24);
  border: 2px solid var(--color-violet-100);
  text-indent: var(--spacing-12);
}

.login-form .right-block .body .input-group-text {
  background-color: var(--color-violet-100);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 0px;
}

.login-form .right-block .body .input-group-text div {
  width: 38px;
  height: 38px;
  padding: var(--spacing-8);
  border-radius: 50%;
  background-color: var(--color-white);
}

.login-form .right-block .body .input-group-text img {
  position: relative;
  top: -4px;
  height: 20px;
}

.login-form .right-block .body .input-group-text img.mail-icon {
  position: relative;
  top: -2px;
  height: 20px;
}

.login-form .right-block .body .input-group-text.append {
  background-color: var(--color-white);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: var(--spacing-24);
  border-bottom-right-radius: var(--spacing-24);
  border: 2px solid var(--color-violet-100);
  border-left: 0px;
}

.login-form .right-block .body .forgot-pw {
  color: #8a8a8a;
}

.login-form .right-block .body .login-btn {
  color: var(--color-white);
  background: linear-gradient(
    180deg,
    var(--color-violet-400) 0%,
    var(--color-violet-100) 100%
  );
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  border: 0px;
  border-radius: 15px;
  margin: var(--spacing-16) 0;
  height: 42.68px;
}

.login-form .right-block .body .google-login-btn {
  background-color: var(--color-white);
  color: var(--color-violet-400);
  /* border-color: linear-gradient(180deg, #7c3bff 0%, #2e087a 100%); */
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 0px;
  border-radius: 15px;
  margin: var(--spacing-16) 0;
  height: 42.68px;
}

.login-form .right-block .body .signup-btn {
  color: var(--color-violet-400);
}

.login-form input,
.login-form input[type="email"] {
  box-shadow: none !important;
  outline: none !important;
}

/* Login page : Footer */
.login-form .footer {
  color: var(--color-white);
  text-align: center;
  margin: 0px auto;
  padding: var(--spacing-128) 0 var(--spacing-16);
  font-size: var(--font-size-bigger);
}

.login-form .footer .company-name {
  padding-top: var(--spacing-32);
  font-size: var(--font-size-small);
}

.login-form .right-block .body input[type="checkbox"] {
  min-height: 0px;
}

/* Forgot pw page */
@media (max-width: 575.98px) {
}

@media (max-width: 767.98px) {
  .login-form .row {
    padding: var(--spacing-128) var(--spacing-4) 0;
  }

  .login-form .left-block {
  }

  .login-form .right-block {
    padding: 0px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
}

@media (min-width: 768px) {
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .login-page .container {
    max-width: 70%;
  }

  .login-form .row {
    min-height: 80vh;
    padding: var(--spacing-160) var(--spacing-16) 0;
  }

  /* forgot pw */
  .forgot-pw-page .right-block {
    max-width: 50vw;
    margin: 0px auto;
  }

  /* signup */
  .signup-page .container.login-form {
    max-width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .login-page .container.login-form,
  .signup-page .container.login-form {
    max-width: 90%;
  }

  /* forgot pw */
  .forgot-pw-page .right-block {
    max-width: 45vw;
    margin: 0px auto;
  }
}

@media (min-width: 1200px) {
  .login-page .container.login-form {
    max-width: 60%;
  }

  .login-form .footer {
    max-width: 25vw;
  }

  /* forgot pw */
  .forgot-pw-page .right-block {
    max-width: 40vw;
    margin: 0px auto;
  }

  /* signup */
  .signup-page .container.login-form {
    max-width: 70%;
  }
}
