.show-transaction-wrapper {
  padding: var(--spacing-48) var(--spacing-16);
  text-align: left;
}

.show-transaction-wrapper .invoice-table {
  border: 2px solid #d5d5d5;
  border-radius: var(--spacing-24);
  margin-top: var(--spacing-24);
  margin-bottom: var(--spacing-24);
}

.show-transaction-wrapper .invoice-table .invoice-table-section {
  padding: var(--spacing-12) var(--spacing-16);
  font-size: var(--font-size);
}

.show-transaction-wrapper .invoice-table .invoice-table-divider {
  margin: 0;
}

.show-transaction-wrapper .transaction-details {
  text-align: center;
}

.show-transaction-wrapper .transaction-details .currency-icon {
  width: 19px;
  height: 19px;
  position: relative;
  left: 3px;
}

.show-transaction-wrapper .transaction-details .transaction-amount {
  margin-bottom: 0;
}

.show-transaction-wrapper .transaction-details small {
  text-transform: capitalize;
  font-size: var(--font-size-smaller);
}

.show-transaction-wrapper .transaction-details .debit {
  color: #b11212;
}

.show-transaction-wrapper .transaction-details .pending {
  color: #ffe427;
}

.show-transaction-wrapper .transaction-details .credit {
  color: #12b118;
}

.show-transaction-wrapper .transaction-note {
  font-size: var(--font-size-small);
  text-align: center;
}

@media (min-width: 768px) {
  .show-transaction-wrapper {
    margin-top: var(--spacing-64);
    padding-top: var(--spacing-64);
  }
}
