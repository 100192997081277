.send-alerts-wrapper {
  padding: var(--spacing-64) var(--spacing-32) 0;
}

.send-alerts-wrapper .form-label {
  font-size: var(--font-size-small);
  margin: 0;
}

.send-alerts-wrapper .form-control,
.send-alerts-wrapper .form-control:active,
.send-alerts-wrapper .form-control:focus,
.send-alerts-wrapper .form-control:hover {
  color: var(--color-black);
  padding: 0;
  margin: 0;
  border-width: 0;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 0;
  background-color: transparent;
  font-size: var(--font-size);
}

.send-alerts-wrapper .created-at {
  color: #2b2b2b;
  font-size: var(--font-size-smaller);
}

@media (max-width: 576px) {
  .send-alerts-wrapper {
    padding: var(--spacing-48) var(--spacing-16) 0;
  }
}
